<template>
    <div class="schedule-class--card" ref="cardWrapper">
        <unpayed-card v-if="statusScheduleInClass === 7"
                      type="weekend"
                      title="Выходные"
                      text="Ждем вас в понедельник!"
                      :class-name="schoolClass.fullName"
                      :school-class="schoolClass.letter" :type-school="schoolClass.type">
        </unpayed-card>
        <div class="schedule-view first" v-else>
            <div class="schedule--stream-container video-check" :class="{'show' : isLessonShow && statusScheduleInClass !== 4  && statusScheduleInClass !== 8}">
              <Slick
                  v-if="camerasSchedule.length > 0"
                  ref="slider"
                  class="slider"
                  :options="slickOptions"
                  @afterChange="handleAfterChange"
                  :class="[`slider${schoolClass.id}`]"
              >
                <div  class="swiper-slide" v-for="(cam,index) in camerasSchedule" v-bind:key="cam.id">
                  <video-item
                      v-if="index === selectedIndexSchedule"
                      v-show="(!$store.getters.IS_FULLSCREEN_CAMERA && !$store.getters.IS_FULLSCREEN_SCHEDULE)  || $store.getters.IS_FULLSCREEN_SCHEDULE === schoolClass.id "
                      :video="cam"
                      :schid="schoolClass.id"
                      type="schedule"
                      ref="camsContainerSchedule"
                      @changeFullscreen="onChangeFullScreen"
                      @changeMute="changeMuteLessons"
                  />
                </div>
              </Slick>
                <div class="users-count--container" v-if="account.type === 1" v-show="isLessonShow && statusScheduleInClass !== 4  && statusScheduleInClass !== 8">
                    <a><p>{{usersCount}}</p><img src="../../assets/img/user.svg"></a>
                </div>
            </div>
            <div class="stream-info--container">
                <div class="info--container">
                    <p class="lesson__name">{{activeLessonName}}</p>
                    <p class="class__name">{{schoolClass.fullName}}  {{(schoolClass.type == 1) ? "группа" : "класс"}}</p>
                </div>
                <div class="time--container">
                    <p v-if="statusScheduleInClass === 2 && fancyTimePreEndTo != '00:00'">Ждем Вас завтра! {{fancyTimePreEndTo}}</p>
                    <p v-else-if="statusScheduleInClass === 2">Ждем Вас завтра!</p>

                    <p v-else-if="statusScheduleInClass !== 2 && secondsTo < 60*60">до звонка {{fancyTimeTo}}</p>

                </div>
                <div class="schedule--container" :class="{'max' : isOpenScheduleContainer}">
                    <div class="schedule--list">
                        <div class="schedule--list-item" v-for="lesson in filteredLessons" v-bind:key="lesson.id">
                            <a><span class="icon-active" v-if="lesson.availableStatus === 1"></span></a>
                            <p :class="{'disable' : lesson.availableStatus === 0}">{{lesson.name}}</p>
                        </div>


                    </div>
                    <div class="slider--container">
                        <b>{{beginTimeString}}</b>
                        <input class="slider" :id="'slider'+schoolClass.id">
                        <b>{{endTimeString}}</b>
                    </div>
                </div>
                <div class="button--container">
                    <a @click="toggleScheduleContainer" class="btn-open" :class="{'rotated' : isOpenScheduleContainer}"><span class="icon-arrow"></span></a>
                    <p :class="{'fake' : isOpenScheduleContainer}"><span class="icon-star"></span>{{howManyLessonsString}}</p>
                </div>
            </div>
            <div class="info-icon--container" v-if="statusScheduleInClass !== 3 && !isOpenScheduleContainer">
                <img src="../../assets/img/hourglass.gif" v-if="statusScheduleInClass === 0" draggable="false">
                <img src="../../assets/img/food.gif" v-else-if="statusScheduleInClass === 4" draggable="false">
                <img src="../../assets/img/peremena.gif" v-else-if="statusScheduleInClass === 1" draggable="false">
                <img src="../../assets/img/bell.gif" v-else-if="statusScheduleInClass === 2" draggable="false">
              <img src="../../assets/img/quietHour.gif" v-else-if="statusScheduleInClass === 8" draggable="false">

            </div>
        </div>
      <div class="slider--icons-schedule" ref="sis" v-show="camerasSchedule.length > 1" :class="[`slider--icons-schedule${this.schoolClass.id}`,(camerasSchedule.length > 0) ? '' : 'not-showed']">
        <button v-for="(camera,index) in camerasSchedule"
                v-bind:key="camera.id"
                @click="$refs.slider.goTo(index)"
                :class="{'active' : selectedIndexSchedule === index}"></button>
      </div>

    </div>

</template>

<script>
    import moment from "moment";
    import Slider from "bootstrap-slider";
    import UnpayedCard from "./UnpayedCard";
    import VideoItem from "../video/VideoItem";
    // import "slick-carousel/slick/slick"
    import 'slick-carousel/slick/slick.css';
    import Slick from 'vue-slick'




    export default {
        name: "ScheduleStreamBlockCard",
        components: {VideoItem, UnpayedCard,Slick},
        props: {
            schoolClass: Object
        },
        watch: {
            selectedIndexSchedule: {
                handler(newVal) {
                    // this.stopSchedule(oldVal)
                    // this.playSchedule(newVal)
                    if (this.activeLessonCameras.length > 0){
                        const cam = this.camerasSchedule[newVal]
                        //@ts-ignore
                        this.isMayBeAudio = !!cam.isAudio;
                    }
                },
                deep: true,
                immediate: true
            }
        },
        computed: {
          slickOptions(){
            const slickOptions = {
              rows: 1,
              infinite: false,
              arrows: false,
              dots: false,
              useTransform: false,
              slidesPerRow: 1
            }
            if (this.isFullScreenSchedule){
              slickOptions.useTransform = false
            }

            return slickOptions
          },
            usersCount(){

                const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
                const lesson1 = lessons.filter((lesson) => {
                    return lesson.availableStatus === 1;
                })[0]
                if (lesson1 != null && lesson1.cameras.length > 0){
                    return lesson1.cameras[this.selectedIndexSchedule].usersCount
                }
                else {
                    return 0
                }
            },



            account(){
                return this.$store.getters.PROFILE
            },

            lessons(){
                if (this.schoolClass.id != null){
                    const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
                    return lessons
                }
                return [];
            },

            filteredLessons(){
                let lessons = this.lessons
                return lessons.filter((lesson) => lesson.name != 'Перерыв')

            },


            activeLessonCameras(){
                if (this.activeLesson){
                    return this.activeLesson.cameras
                } else if (this.nextAvailableLesson1){
                    return this.nextAvailableLesson1.cameras
                }

                return []
            },

            isSchoolTimeNotBegin(){
                if (this.timeNow === 0) return true;

                const schedules = this.lessons;
                if (schedules.length > 0){
                    const begin = schedules[0].begin;
                    //@ts-ignore
                    return this.timeNow < moment(begin.date).toDate().getTime();
                } else {
                    return true;
                }

            },

            isSchoolTimeAlreadyEnd(){
                const time = this.$store.getters.TIME_NOW
                if (time === 0) return true;


                const schedules = this.lessons;
                if (schedules.length > 0){
                    const end = schedules[schedules.length-1].end;
                    //@ts-ignore
                    return time > moment(end.date).toDate().getTime();
                } else {
                    return true;
                }
            },

            activeLesson(){
                if (this.schoolClass.id != null){
                    const activeLesson = this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
                    if (this.selectedIndexSchedule == null){
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.selectedIndexSchedule = 0
                    }
                    if (activeLesson != null && activeLesson != false){
                        if (activeLesson.name == 'Перерыв' || activeLesson.name === 'Тихий час'){
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            this.isMayBeAudio = false
                        } else {
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            this.isMayBeAudio = !!activeLesson?.cameras[this.selectedIndexSchedule].isAudio;
                        }
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        // this.isMayBeAudio = !!activeLesson?.cameras[this.selectedIndexSchedule].isAudio;

                    }
                    if (activeLesson != false && this.actLessonNow?.id != activeLesson.id && activeLesson != null){
                      this.closeAllFullscreen()

                      // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                      this.selectedIndexSchedule = 0

                      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                      this.actLessonNow = activeLesson

                        console.log(activeLesson.cameras, "камеры блят")
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.camerasSchedule = [...activeLesson.cameras]
                        // eslint-disable-next-line vue/no-async-in-computed-properties
                        // setTimeout(() => this.initScheduleCarousel(),2000)
                      this.initScheduleCarousel()
                        this.initSlider()


                        console.log("aim duit")
                    } else if (activeLesson == false){
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        if (this.isSchoolTimeAlreadyEnd && this.isPreShowEndSchool ){

                          const lesson1 = this.lessons[this.lessons.length-1]
                          if (lesson1?.cameras.length >= this.selectedIndexSchedule+1){
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                            this.isMayBeAudio = !!lesson1?.cameras[this.selectedIndexSchedule].isAudio;
                          }
                            if ((this.nextAvailableLesson1 == null || this.nextAvailableLesson1.id !== lesson1.id)){
                              this.closeAllFullscreen()
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                              this.selectedIndexSchedule = 0
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                                this.nextAvailableLesson1 = lesson1
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                                this.camerasSchedule = [...lesson1.cameras]
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                              this.initScheduleCarousel()
                              return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
                            }
                            return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
                        } else if (this.isSchoolTimeNotBegin && this.secondsToBeginSchool > 0 && this.isPreShowBeginSchool){

                          const lesson1 = this.lessons[0]
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                          if (lesson1?.cameras.length >= this.selectedIndexSchedule+1){
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                            this.isMayBeAudio = !!lesson1?.cameras[this.selectedIndexSchedule].isAudio;
                          }
                          if ((this.nextAvailableLesson1 == null || this.nextAvailableLesson1.id !== lesson1.id)){
                            this.closeAllFullscreen()
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                              this.selectedIndexSchedule = 0
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                                this.nextAvailableLesson1 = lesson1
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                                this.camerasSchedule = [...lesson1.cameras]
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                            this.initScheduleCarousel()
                            return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
                            }
                            return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
                        } else if (this.isRelax){

                            const nextAvailableLesson =  this.nextAvailableLesson
                          if (nextAvailableLesson?.cameras.length >= this.selectedIndexSchedule+1){
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                            this.isMayBeAudio = !!nextAvailableLesson?.cameras[this.selectedIndexSchedule].isAudio;
                          }
                            if (this.nextAvailableLesson1 == null || this.nextAvailableLesson1.id !== nextAvailableLesson.id){
                              this.closeAllFullscreen()
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                              this.selectedIndexSchedule = 0
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.nextAvailableLesson1 = nextAvailableLesson
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.camerasSchedule = [...nextAvailableLesson.cameras]
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                              this.initScheduleCarousel()
                              return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
}
                            return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
                        } else {
                          this.closeAllFullscreen()
                          // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                            this.isMutedLesson = true;
                          this.muteSchedulesNew();

                          // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                            this.isMayBeAudio = false
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                            this.nextAvailableLesson1 = null
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
                            this.camerasSchedule = [];

                        }
                    }

                    return this.$store.getters.ACTIVE_LESSONS(this.schoolClass.id)
                } else return {}

            },


            timeNow(){
                return this.$store.getters.TIME_NOW
            },


            statusScheduleInClass(){
                const profileSchool = this.$store.getters.SCHOOL
                if (!this.$store.getters.PAY_STATUS ){
                    return 3
                } else if (profileSchool != null && profileSchool.isHoliday){
                    return 6
                } else if (this.lessons.length === 0){
                    return 7
                }
                else if (this.isSchoolTimeNotBegin){
                    return 0
                } else if (this.activeLesson.name === 'Перерыв'){
                    //@ts-ignore
                    // const camera : any = this.cams[this.swiper?.realIndex]
                    // if (camera == null || !camera.isKitchen){
                    //     this.cams.forEach((x : any, index1) => {
                    //         if (x.isKitchen) {
                    //             index = index1;
                    //         }
                    //     });
                    //
                    //     console.log("index",index)
                    //     if (index != null){
                    //         // @ts-ignore
                    //         this.swiper?.slideTo(index)
                    //     }
                    // }
                    return 4
                } else if (this.activeLesson.name === 'Тихий час'){
                  return 8
                } else if (this.isRelax){
                    return 1
                } else if (this.isSchoolTimeAlreadyEnd){
                    return 2
                }

                else {
                    return 5
                }
            },


            activeLessonName(){
                switch (this.statusScheduleInClass) {
                    case 0:
                        return "Ждем начала занятий"
                    case 1:
                        return "Сейчас идет перемена"
                    case 2:
                        return "Учебный день окончен"
                    case 3:
                        return ""
                    case 4:
                        return "Обеденный перерыв"
                    case 5:
                        return this.activeLesson.name
                    case 8:
                        return "Тихий час"
                    default:
                        console.log(this.statusScheduleInClass())
                        return ""
                }
            },

            nextAvailableLesson(){
                //@ts-ignore
                const availableLessons = this.lessons.filter(schedule => schedule.availableStatus === 2);
                if (availableLessons.length === 0) return false;
                return availableLessons[0];
            },

            beginTimeString(){
                if (this.schoolClass.id != null){
                    const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
                    if (lessons.length > 0){
                        const date = moment(lessons[0].begin.date)
                        // console.log(date)
                        return date.format("HH:mm")
                    }
                }
                return ""
            },

            endTimeString(){
                if (this.schoolClass.id != null){
                    const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
                    if (lessons.length > 0){
                        const date = moment(lessons[lessons.length-1].end.date)
                        return date.format("HH:mm")
                    }
                }
                return ""
            },



            isLessonShow(){
                if (this.lessons.length > 0){
                    if (this.activeLesson || this.statusScheduleInClass == 1 || (this.statusScheduleInClass == 0 && this.isPreShowBeginSchool) || (this.statusScheduleInClass == 2 && this.isPreShowEndSchool)) {
                        return true
                    }
                }
                return false
            },


            secondsTo(){
                if (this.lessons.length > 0){
                    const timeNow = this.$store.getters.TIME_NOW
                    if (this.activeLesson){
                        return Math.round((moment(this.activeLesson.end.date).toDate().getTime()-timeNow)/1000)
                    } else {
                        const lesson = this.nextAvailableLesson
                        if (lesson){
                            return Math.round((moment(lesson.begin.date).toDate().getTime()-timeNow)/1000)
                        }
                    }
                }
                return -1;
            },


            secondsToEndSchool(){
                if (this.lessons.length > 0){
                    const timeNow = this.$store.getters.TIME_NOW
                    const lesson = this.lessons[this.lessons.length-1]
                    if (lesson){
                        return Math.round((moment(lesson.end.date).toDate().getTime()-timeNow)/1000)
                    }
                }
                return -1;
            },


            secondsToBeginSchool(){
                if (this.lessons.length > 0){
                    const timeNow = this.$store.getters.TIME_NOW
                    const lesson = this.lessons[0]
                    if (lesson){
                        return Math.round((moment(lesson.begin.date).toDate().getTime()-timeNow)/1000)
                    }
                }
                return -1;
            },

            isPreShowBeginSchool(){
                if (this.lessons.length > 0){
                    const lesson = this.lessons[0]
                    if (lesson){
                        return Math.abs(this.secondsToBeginSchool) <= (lesson.durationBreak * 60)
                    }
                }
                return false;
            },

            isPreShowEndSchool(){
                if (this.lessons.length > 0){
                    const lesson = this.lessons[this.lessons.length-1]
                    if (lesson){
                        return Math.abs(this.secondsToEndSchool) <= (lesson.durationBreak * 60)
                    }
                }
                return false;
            },


            howManyLessonsString(){
                if (this.filteredLessons.length > 0){
                    const count = this.filteredLessons.filter((lesson) => lesson.availableStatus == 2).length
                    const correctedString = this.declToString(count)
                    if (count != 0 && count === this.filteredLessons.length){
                        return `Сегодня ${count} ${correctedString}`
                    } else if ( (count === 1 && !this.activeLesson)  || (count === 0 && this.activeLesson)){
                        return "Последний урок"
                    } else if (count === 0){
                        return ""
                    } else {
                        return `Еще ${count} ${correctedString}`
                    }
                }
                return ""
            },

            fancyTimeTo(){
                const seconds = this.secondsTo
                return (seconds !== -1) ? this.fancyTimeFormat(seconds) : "00:00"
            },


            fancyTimePreEndTo(){
                let seconds = -1
                if (this.lessons.length > 0){
                    const lesson = this.lessons[this.lessons.length-1]
                    if (lesson){
                        seconds = (lesson.durationBreak * 60) -  Math.abs(this.secondsToEndSchool)
                    }
                }
                return (seconds !== -1) ? this.fancyTimeFormat(seconds) : "00:00"
            },




            secondsToEndSchoolDay(){
                const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
                const time = this.$store.getters.TIME_NOW
                if (lessons.length > 0){
                    return Math.round((time - (moment(lessons[0].begin.date).toDate().getTime())) / 1000)
                }
                return 0
            },


            isRelax(){
                if (!(this.isSchoolTimeNotBegin || this.isSchoolTimeAlreadyEnd)){
                    //@ts-ignore
                    return this.lessons.filter(lesson => lesson.availableStatus === 1).length === 0
                } else {
                    return false;
                }
            }
        },
        data() {
            return {
                isOpenScheduleContainer: false,
                selectedCameraSchedule: {},
                isMayBeAudio: null,
                camerasSchedule: [],
                isMutedLesson: true,
                selectedIndexSchedule: 0,
                cameraScheduleSlider: null,
                nextAvailableLesson1: null,
                actLessonNow: {},
                slider : null,
              isFullScreenSchedule : false,
              activeSlide: 0,
              slickSlider: null

            }
        },
        methods:{
          handleAfterChange (event, slick, currentSlide) {
            this.selectedIndexSchedule = currentSlide
            this.isMutedLesson = true

              const camera = this.camerasSchedule[this.selectedIndexSchedule]
              this.selectedCameraSchedule = {...camera}
          },
            fancyTimeFormat(time)
            {
                if (time < 0) return "00:00";
                // Hours, minutes and seconds
                var mins = ~~((time % 3600) / 60);
                var secs = ~~time % 60;

                // Output like "1:01" or "4:03:59" or "123:03:59"
                var ret = "";

                ret += "" + (mins < 10 ? "0" : "");
                ret += "" + mins + ":" + (secs < 10 ? "0" : "");
                ret += "" + secs;
                return ret;
            },

            declToString(count){
                if (count === 1){
                    return "урок"
                } else if (count > 1 && count < 5){
                    return "урока"
                } else {
                    return "уроков"
                }
            },

          async onChangeFullScreen(isFullscreen){
            this.isFullScreenSchedule = isFullscreen
            if (isFullscreen){
              this.$emit("audio")
            }
            // this.initScheduleCarousel()
            // // eslint-disable-next-line no-undef
            // var owl = $(`.owl-carousel-schedule${this.schoolClass.id}`);
            // this.isFullScreenSchedule = isFullscreen
            // if (this.isFullScreenSchedule){
            //   //@ts-ignore
            //   owl.trigger("destroy.owl.carousel")
            //   //@ts-ignore
            //   document.getElementsByClassName(`owl-carousel-schedule${this.schoolClass.id}`)[0].style.display = 'block'
            // } else if (!this.isFullScreenSchedule) {
            //   this.createScheduleCarouselInstance(owl)
            // }
          },


          createScheduleCarouselInstance(){
            // const div = document.createElement("div")
            // div.classList.add(`slider--icons-schedule${this.schoolClass.id}`)
            // div.style.width = "100%"
            // div.style.marginTop = "21px"
            // div.style.display = "flex"
            // div.style.justifyContent = "center"
            // div.style.height = "4px"
            // //@ts-ignore
            //
            // if (this.camerasSchedule.length > 1){
            //   this.$refs.cardWrapper.insertBefore(div,null)
            // }
            //
            // //@ts-ignore
            // owl.owlCarousel({
            //   items:1,
            //   dotsContainer : `.slider--icons-schedule${this.schoolClass.id}`,
            //   startPosition: this.selectedIndexSchedule
            // })
            //
            //
            // owl.on('changed.owl.carousel', (event) =>  {
            //   if (this.$refs.camsContainerSchedule[this.selectedIndexSchedule] != null){
            //     this.$refs.camsContainerSchedule[this.selectedIndexSchedule].mute();
            //   }
            //   this.selectedIndexSchedule = event.item.index
            //   this.isMutedLesson = true
            //
            //   const camera = this.camerasSchedule[this.selectedIndexSchedule]
            //   this.selectedCameraSchedule = {...camera}
            // })
          },

          isIterable(obj) {
            // checks for null and undefined
            if (obj == null) {
              return false;
            }
            return typeof obj[Symbol.iterator] === 'function';
          },

          closeAllFullscreen(){
            console.log()
            if (this.isIterable(this.$refs.camsContainerSchedule)){
              for (const video of this.$refs.camsContainerSchedule){
                if (video.isFullScreen){
                  video.exitScreen()
                }
              }
            }
          },



            initScheduleCarousel(){
              let currIndex = 0

              if (this.$refs.slider){
                this.$refs.slider.destroy()

              }

              this.$nextTick(() => {
                this.$refs.slider.create(this.slickOptions)
                this.$refs.slider.goTo(currIndex, true)
              })


                //   if (this.camerasSchedule.length > 0){
                //     this.playSchedule(0)
                //
                //     //@ts-ignore
                //     const camera = this.camerasSchedule[this.selectedIndexSchedule]
                //     this.selectedCameraSchedule = {...camera}
                //   }
                // })




              // this.$nextTick(() => {
              //   this.$refs.slider.reSlick();
              // });
              // // eslint-disable-next-line no-undef
              // var owl = $(`.owl-carousel-schedule${this.schoolClass.id}`);
              // owl.owlCarousel({
              //   items:1,
              //   dotsContainer : `.slider--icons-schedule${this.schoolClass.id}`,
              //   nav: null,
              //   startPosition: this.selectedIndexSchedule
              // })
              //
              //
              // owl.on('changed.owl.carousel', (event) =>  {
              //   if (this.$refs.camsContainerSchedule[this.selectedIndexSchedule] != null){
              //     this.$refs.camsContainerSchedule[this.selectedIndexSchedule].mute();
              //   }
              //   this.selectedIndexSchedule = event.item.index
              //   this.isMutedLesson = true
              //
              //   const camera = this.camerasSchedule[this.selectedIndexSchedule]
              //   this.selectedCameraSchedule = {...camera}
              // })
              //
              // owl.trigger( 'to.owl.carousel', [this.selectedIndexSchedule] )
              //
              //
            },


            initSlider(){
                if (this.schoolClass.id != null){
                    if (this.slider != null){
                        this.slider.destroy();
                        this.slider = null
                    }
                    const lessons = this.$store.getters.LESSONS(this.schoolClass.id)
                    const lastLesson = lessons[lessons.length-1]
                    const firstLesson = lessons[0]
                    if (![3,6,7].includes(this.statusScheduleInClass)){
                        if (lessons.length > 0){
                          try{
                            const seconds = Math.round((moment(lastLesson.end.date).toDate().getTime()-moment(firstLesson.begin.date).toDate().getTime())/1000)
                            console.log("seconds max")


                            this.slider = new Slider("#slider"+this.schoolClass.id,{
                              orientation: 'vertical',
                              min: 0,
                              max: seconds,
                              step: 1
                            })

                            setInterval(() => {
                              this.slider.setValue(this.secondsToEndSchoolDay)
                            },1000)
                          } catch (e){
                            console.log(e)
                          }
                        }
                    }
                }

            },

            gotofullsize(arr,name  = ""){
                let e = {};
                if (name === 'shared'){
                    //@ts-ignore
                    e = arr[this.selectedIndex].$el.querySelector('video')
                } else {
                    //@ts-ignore
                    e = arr[this.selectedIndexSchedule].$el.querySelector('video')
                }
                console.info(e,"видео")
                if (e.requestFullscreen) {
                    e.requestFullscreen();

                } else if (e.mozRequestFullScreen) {
                    e.mozRequestFullScreen();
                } else if (e.webkitRequestFullscreen) {
                    e.webkitRequestFullscreen();
                } else if (e.msRequestFullscreen) {
                    e.msRequestFullscreen();
                } else {
                    e.webkitSetPresentationMode("fullscreen");
                    console.log("not work why")
                }
            },


            playSchedule(index){
                console.log(this.$refs.camsContainerSchedule)
                console.log("camsContainerSchedule",this.$refs.camsContainerSchedule)
                if (this.$refs.camsContainerSchedule == null) return;
                //@ts-ignore
                this.$refs.camsContainerSchedule[index].playVideo();
            },

            stopSchedule(index){
                console.log(this.$refs.camsContainerSchedule)
                if (this.$refs.camsContainerSchedule == null || this.$refs.camsContainerSchedule.length-1 < index) return;
                //@ts-ignore
                this.$refs.camsContainerSchedule[index].stopVideo();
            },

            muteSchedule(){
              this.muteSchedulesNew()
            },


            changeMuteLessons(isMuted){
              this.isMutedLesson = isMuted
              if (!this.isMutedLesson){
                this.$emit("audio")
              }
            },


          muteSchedulesNew(){
            const cam = this.camerasSchedule[this.selectedIndexSchedule]
            if (cam != null && cam.isAudio){
              this.isMutedLesson = true
              //@ts-ignore
              for(let cam of this.$refs.camsContainerSchedule )
                  //@ts-ignore
                cam.mute()
            }
          },



            toggleScheduleContainer(){
                this.isOpenScheduleContainer = !this.isOpenScheduleContainer
            }
        },
        beforeDestroy() {
            if (this.$refs.camsContainerSchedule != null && this.$refs.camsContainerSchedule.length > 0){
                this.$refs.camsContainerSchedule[this.selectedIndexSchedule].fullStop();
            }
            if (this.cameraScheduleSlider != null){
                this.cameraScheduleSlider.destroy();

            }
            this.nextAvailableLesson1 = null;
            this.camerasSchedule = [];
            this.actLessonNow = {};

            this.selectedCameraSchedule = {}
        },

        mounted(){
            setTimeout(() => {
                this.initSlider()
            },2000)
        },

    }
</script>

<style scoped lang="scss">

    .schedule-class--card{
        margin-bottom: 20px;
    }

    .unpayed--view{
        background: #FFFFFF;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
        border-radius: 4.37px;
        display: flex;
        height: 70px;

        @include for-phone-only{
            width: 375px;

        }

        @include for-phone-landscape-up{
            width: 575px;
        }

        @include for-tablet-up{
            width: 768px;
        }

        @include for-desktop-up{
            width: 768px;

        }

        @include for-big-desktop-up{
            width: 768px;
        }


        .image--container{
            margin-left: 22px;
            margin-top: 15px;
        }

        .text--container{
            margin-top: 15px;
            margin-left: 20px;
            width: 398px;
            .title__text{
                font-family: Roboto, "sans-serif";
                font-size: 16px;
                letter-spacing: 0;
                color: #CF6868;
                font-weight: 400;
            }

            .text{
                font-family: Roboto, "sans-serif";
                font-size: 14px;
                letter-spacing: 0;
                color: black;
                margin-top: 3px;

                @include for-mobile-devices{
                    font-size: 12px;
                }
            }
        }

        .class-name--container{
            margin-left: 152px;
            margin-top: 22px;


            @include for-phone-only{
                margin-left: 0px;

            }

            @include for-phone-landscape-up{
                margin-left: 152px;
            }

            @include for-tablet-up{
                margin-left: 152px;
            }

            @include for-desktop-up{
                margin-left: 152px;

            }

            @include for-big-desktop-up{
                margin-left: 152px;
            }

            .class__name{
                margin-right: 16px;
                font-family: Roboto, "sans-serif";
                letter-spacing: 0;
                font-size: 14px;
                color: #222222;

                background: #F4F4F4;
                width: 98px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 13px;

                pointer-events: none;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
            }
        }
    }

    .schedule-view{
        width: 375px;
        height: auto;
        max-height: 825px;
        background: #FFFFFF;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
        border-radius: 4.37px;
        display: flex;
        flex-direction: column;
        transition: all 0.3s;
        position: relative;
        opacity: 1;

        &.minified{
            max-height: 0;
            overflow: hidden;
            transition: all 0.3s;
        }


        @include for-phone-only{
            width: 375px;

        }

        @include for-phone-landscape-up{
            width: 575px;
        }

        @include for-tablet-up{
            width: 768px;
        }

        @include for-desktop-up{
            width: 768px;

        }

        @include for-big-desktop-up{
            width: 768px;
        }
        &.min{

        }

        .schedule--stream-container{
            width: 375px;
            height: 0px;
            min-height: 0px;
            /*position: relative;*/
            display: flex;
            transition: all 0.3s;
            border-radius: 4.37px;

            .no-audio{
                opacity: 0;
            }


            @include for-phone-only{
                width: 375px;

            }

            @include for-phone-landscape-up{
                width: 575px;
            }

            @include for-tablet-up{
                width: 768px;
            }

            @include for-desktop-up{
                width: 768px;

            }

            @include for-big-desktop-up{
                width: 768px;
            }


            .rounded{
                border-radius: 4.5px 4.5px 0 0;
                z-index: 1;
            }


            &.show{
                height: 239px;
                min-height: 239px;

                @include for-phone-only{
                    height: 239px;
                    min-height: 239px;
                }

                @include for-phone-landscape-up{
                    width: 575px;
                    height: 440px;
                    min-height: 440px;

                }

                @include for-tablet-up{
                    height: 588px;
                    min-height: 588px;
                }

                @include for-desktop-up{
                    height: 588px;
                    min-height: 588px;

                }

                @include for-big-desktop-up{
                    height: 588px;
                    min-height: 588px;
                }

                video{
                    height: 239px;

                    @include for-phone-only{
                        width: 375px;

                    }

                    @include for-phone-landscape-up{
                        width: 575px;
                        height: 440px;
                        min-height: 440px;

                    }

                    @include for-tablet-up{
                        width: 768px;
                        height: 588px;
                    }

                    @include for-desktop-up{
                        width: 768px;
                        height: 588px;

                    }

                    @include for-big-desktop-up{
                        width: 768px;
                        height: 588px;
                    }
                }
            }

            .bottom--container{
                z-index: 1;
                position: absolute;
                right: 0px;
                /*bottom: 107px;*/
                top: 507px;

                opacity: 0;
                transition: all 0.3s;
                height: 80px;
                width: 100%;
                background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.60) 99%);



                @include for-phone-only{
                    top: 159px
                }

                @include for-phone-landscape-up{
                    top: 360px;
                }

                a{
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    cursor: pointer;
                    transition: all 0.3s;
                    z-index: 3;
                    &:hover{
                        transform: scale(1.2);
                    }
                }
            }


            .audio-controls--container{
                top: 543px;

                @include for-phone-only{
                    top: 197px;
                }

                @include for-phone-landscape-up{
                    top: 397px;
                }
            }

            &:hover{
                .bottom--container{
                    opacity: 1;
                }

                .audio-controls--container{
                    opacity: 1 !important;
                    background-color: transparent;


                    p{
                        background-color: transparent;
                    }
                }
            }

            video{
                width: 375px;
                height: 0px;
                object-fit: cover;
            }
        }

        .stream-info--container{
            .info--container{
                display: flex;
                align-items: center;
                justify-content: space-between;

                .lesson__name{
                    margin-left: 17px;
                    font-family: Roboto, "sans-serif";
                    letter-spacing: 0;
                    font-size: 17px;
                    color: #333942;
                }

                .class__name{
                    margin-right: 16px;
                    font-family: Roboto, "sans-serif";
                    letter-spacing: 0;
                    font-size: 14px;
                    color: #222222;

                    background: #F4F4F4;
                    width: 98px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 13px;
                    pointer-events: none;
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                }
            }

            .time--container{
                margin-top: 4.66px;
                margin-left: 18px;

                p{
                    font-size: 13px;
                    font-family: Roboto, "sans-serif";
                    letter-spacing: 0;
                    color: #606A7B;
                  -webkit-text-size-adjust: 100%

                }
            }

            .schedule--container{
                height: auto;
                max-height: 0;
                transition: all 0.5s ease-in-out;
                opacity: 0;
                pointer-events: none;
                display: flex;
                justify-content: space-between;
                overflow: hidden;

                .schedule--list{
                  width: 225px;
                  height: auto;
                  margin-top: 16px;
                  margin-left: 22px;

                    .schedule--list-item{
                        display: flex;
                        a{
                            margin-right: 7.5px;
                            span{
                                font-size: 8px;
                            }
                        }

                        p{
                            font-family: Roboto, "sans-serif";
                            font-size: 14px;
                            letter-spacing: 0px;
                            line-height: 20px;
                            color: black;
                          width: 225px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;

                            &.disable{
                                color: #828282;
                            }
                        }
                    }
                }

                .slider--container{
                    height: 106px;
                    width: 2px;
                    margin-top: 13px;
                    display: flex;
                    flex-direction: column;
                    margin-right: 105px;

                    b{
                        margin-left: 16px;
                        font-size: 14px;
                        font-family: Roboto, "sans-serif";
                        letter-spacing: 0;
                        color: #828282;
                    }
                }


                .slider--container:first-child{

                }

                &.max{
                  max-height: 360px;
                  opacity: 1;
                }
            }

            .button--container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
                margin-bottom: 18px;
                margin-right: 20px;
                margin-left: 18px;

                p{
                    display: flex;
                    align-items: center;
                    margin-top: 8px;
                    font-family: Roboto, "sans-serif";
                    font-size: 13.1px;
                    color: #606A7B;
                    letter-spacing: 0;
                    line-height: 19.66px;
                    span{
                        margin-right: 4px;
                    }
                }

                a{
                    animation: rotating-below 0.5s linear;

                }

                a.rotated{
                    transform: rotate(180deg);
                    animation: rotating 0.5s linear;
                }

                .btn-open{
                    width: 26px;
                    height: 26px;
                    cursor: pointer;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        font-size: 19px;
                    }

                    &:hover{
                        background-color: #F4F4F4;
                    }
                }
            }
        }

        .info--container{
            margin-top: 17px;
        }
    }

    .info-icon--container{
        position: absolute;
        right: 364px;
        bottom: 30px;

        @include for-phone-only{
            right: 160px;
            bottom: 20px;
            display: none;
        }

        @include for-phone-landscape-up{
            right: 240px;
            bottom: 30px;
        }
    }

    .info-icon--container{
        img{
            width: 50px;
        }
    }

    .slider--icons-schedule{
        margin-top: 21px;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 4px;

        button{
            width: 24px !important;
            height: 4px !important;
            border-radius: 2.5px !important;
            margin-right: 8px;
            background-color: #EBECEF !important;
            opacity: 1 !important;
            border: 0 !important;
            cursor: pointer;
        }

        .active{
            background-color: #F54E5E !important;
        }

    }

    .audio-controls--container{
        position: absolute;
        left: 17px;
        bottom: 24px;
        opacity: 0;
        border-radius: 50%;
        background-color: rgba(0,0,0,.5);
        z-index: 1;


        p{
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
        }

        @include for-mobile-devices{
            bottom: 15px;

        }

        @include for-desktop-devices{
            bottom: 17px;
        }

        &.no-audio{
            left: 0px;

            background-color: transparent;
            border-radius: 50%;


            p{
                font-family: Roboto, "sans-serif";
                font-weight: 100;
                font-size: 13px;
                width: 91px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                /*background-color: rgba(0,0,0,.65);*/
                border-radius: 14px;
                letter-spacing: 0.34px;
                transition: all 0.3s;
                color: white;

                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */


                @include for-mobile-devices{
                    font-size: 11px;
                }
            }
        }

        a{
            cursor: pointer;
            transition: all 0.3s;


            &:hover{
                transform: scale(1.2);
            }

            span{
                color: white;
                font-size: 28px;
            }
        }
    }

    .not-showed{
        display: none !important;
    }

    .users-count--container{
        position: absolute;
        width: 52px;
        height: 19px;
        border-radius: 4px;
        top:10px;
        left: 10px;
        background: rgba(41,41,41,.55);
        display: flex;
        align-items: center;

        a{
            display: flex;
            align-items: center;

            p{
                font-family: Roboto, "sans-serif";
                font-size: 13px;
                margin-left: 8px;
                letter-spacing: 0;
                color: white;
                width: 23px;
            }

            img{
                margin-left: 4px;
            }
        }
    }


    .slider{
      @include for-phone-only{
        width: 375px !important;
        height: 239px !important;
      }

      @include for-phone-landscape-up{
        width: 575px !important;
        height: 440px !important;
      }

      @include for-tablet-up{
        width: 768px !important;
        height: 587px !important;
      }

      @include for-desktop-up{
        width: 768px !important;
        height: 587px !important;

      }

      @include for-big-desktop-up{
        width: 768px !important;
        height: 587px !important;

      }
    }

</style>
