export class WebsocketFactory {
    static make(url,openHandler,onMessageHandler,onErrorHandler,onCloseHandler){
        const ws = new WebSocket(url);
        ws.onmessage = onMessageHandler;
        ws.onopen = openHandler;
        ws.onerror = onErrorHandler;
        ws.onclose = onCloseHandler
        return ws;
    }


}
