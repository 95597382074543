<template>
  <div class="video"
       ref="wrapper"
       :class="isShowControls && !isFullScreen ? 'video_bg' : ''"
       @click="clickToVideo"
       @mouseover="showControls"
       @mouseleave="hideControls">
    <div class="video__video" ref="videoWrap" :style="getWrapperStyles">
      <div :style="getVideoStyles" class="video__container" ref="videoContainer">
        <video ref="video"
               class="video__item"
               :class="[videoSet.current.z > 1 ? 'video__item_grab' : '',
                   countTap === 2 ? 'video__item_translate' : '',
                   isFullScreen ? 'video__item_fullscreen' : '']"
               playsinline autoplay muted></video>
        <div class="video__filter-container" :style="getFilterStyles">
          <filter-object
              v-if="mut.height != null"
              :height="mut.height"
              :width="mut.width"
              :left="mut.left"
              :top="mut.top"
              :is-round="mut.isRound"/>
        </div>
      </div>
      <video-interface
          v-if="isFullScreen"
          :video="video"
          :hasAudio="hasAudio"
          :stop="stop"
          :zoom="videoSet.current.z"
          :volume="volume"
          @changePlay="changePlay"
          @changeZoom="changeZoom"
          @exitScreen="exitScreen"
          @changeVolume="changeVolume"
      ></video-interface>
    </div>
    <div class="video__loader" v-if="isLoading">
      <video-loader></video-loader>
    </div>
    <div class="video__info" v-if="isShowControls">
      <div v-if="hasAudio !== null" class="video__audio">
        <div v-if="hasAudio" class="video__sound">
          <a class="icon icon-mute-1 volume-off--image" @click="changeVolume(1)" v-if="isMuted"
             :class="{'show' : isShowControls}"></a>
          <a class="icon icon-loud volume-on--image" @click="changeVolume(0)" v-else
             :class="{'show' : isShowControls}"></a>
        </div>
        <div v-else class="video__no-sound">
          <div class="no-audio">Без звука</div>
        </div>
      </div>
      <div class="video__fullscreen" @click="goToFullSize">
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="video__svg">
          <path d="M17.6567 1.84998H24.3732V8.44998" stroke="white" stroke-width="2" stroke-linejoin="round"/>
          <path d="M8.70142 1.84998H1.98502V8.44998" stroke="white" stroke-width="2" stroke-linejoin="round"/>
          <path d="M17.6567 20.5499H24.3732V13.9499" stroke="white" stroke-width="2" stroke-linejoin="round"/>
          <path d="M8.70142 20.5499H1.98502V13.9499" stroke="white" stroke-width="2" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as Hls from 'hls.js'
import VideoInterface from "./VideoInterface";
import VideoLoader from "@/components/loader/VideoLoader";
import FilterObject from "@/components/FilterObject";
import ResizeObserver from 'resize-observer-polyfill';
import * as panzoom from "panzoom"



export default {
  name: "VideoItem",
  components: {FilterObject, VideoLoader, VideoInterface},
  props: {
    video: Object,
    frame: Object,
    type: String,
    schid: Number
  },
  data() {
    return {
      volume: 0,
      zoom: 1,
      mut: {},
      isLoading: true,
      clickToView: false,
      hasAudio: null,
      isMuted: true,
      isShowControls: false,
      stop: false,
      showError: false,
      isFullScreen: false,
      desktopZoomInstance: null,
      isMobile: null,
      countTap: 0,
      position: {
        top: 0,
        bottom: 0
      },
      isIos: false,
      videoSet: {
        pinchStart: {
          x: 0,
          y: 0,
        },
        originalSize: {
          width: 0,
          height: 0
        },
        current: {
          x: 0,
          y: 0,
          z: 1,
          zooming: false,
          width: 0,
          height: 0
        },
        last: {
          x: 0,
          y: 0,
          z: 1
        },
        type: undefined,
        delta: {x: undefined, y: undefined}
      },
      filterContainerStyle:{
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    getScale() {
      return this.videoSet.current.z
    },
    getVideoStyles() {
      if (this.isFullScreen) {
        if (this.videoSet.current.z !== 1 && this.isMobile) {
          return {
            'transform': 'translate3d(' + this.videoSet.current.x + 'px,' + this.videoSet.current.y + 'px, 0) scale(' + this.videoSet.current.z + ')'
          }
        }

      }
      return {'transform': 'translate3d(0,0,0) scale(1)'}
    },
    getWrapperStyles() {
      if (this.isIos && this.isFullScreen) {
        return {
          position: 'fixed',
          top: -1,
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: 'calc(100% + 1px)',
          'z-index': 9999,
          background: '#000'
        }
      }
      return {}
    },
    getShow() {
      return true
    },
    getVolume() {
      return this.volume
    },

    getFilterStyles(){
      return this.filterContainerStyle
    }
  },
  methods: {
    changeTap() {
      if (this.$windowWidth < 993 && this.isFullScreen && this.videoSet.current.z > 1) {
        this.countTap++
      }
    },

    changeVolume(size) {
      console.log(size,this.isMuted)

      this.volume = size
      this.toggleMute(size)

      this.$refs.video.volume = size
    },
    computedFilterObject(){
      let video = this.$refs.video
      if (video == null || this.video.blurPreference == null) return {}
      this.filterContainerStyle = {...{
        height : video.clientHeight + 'px',
          width: video.clientWidth + 'px',
          position: 'absolute',
      }}

      const xcoef = video.clientWidth/this.video.blurPreference.cw
      const ycoef = video.clientHeight/this.video.blurPreference.ch
      const obj ={
        top: `${this.video.blurPreference.y*ycoef}px`,
        left: `${this.video.blurPreference.x*xcoef}px`,
        width: `${this.video.blurPreference.w*xcoef}px`,
        height: `${this.video.blurPreference.h*ycoef}px`,
        isRound: this.video.blurPreference.isRound
      }
      return obj;
    },
    loadShow() {
      const video = this.$refs.wrapper.parentNode
      this.position.top = video.offsetTop
      this.position.bottom = this.position.top + video.clientHeight
    },
    generateVideo() {
      const video = this.$refs.video
      this.hlsPlayer = new Hls({
        liveBackBufferLength: 5,
        backBufferLength: 5,
        liveMaxBackBufferLength: 5,
        maxBufferSize: 30,
        maxBufferLength: 5,
        capLevelToPlayerSize: true,
        liveSyncDurationCount: 1
      })
      video.volume = 0
      if (Hls.isSupported()) {
        this.hlsPlayer.on('hlsFragChanged', (e, data) => {
          const range = {
            startOffset: 0,
            endOffset: data.frag.startDTS
          }
          this.hlsPlayer.trigger('hlsBufferFlushing', range)
        })
      }
    },
    playVideo() {
      const video = this.$refs.video
      this.isLoading = true
      video.muted=true
      this.isMuted = true

      this.videoSet.originalSize = {
        width: this.$refs.video.clientWidth,
        height: this.$refs.video.clientHeight
      }

      if (Hls.isSupported()) {
        this.hlsPlayer.loadSource(this.video.uri)
        this.hlsPlayer.attachMedia(video)
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.video.uri
      }

      video.addEventListener('waiting', () => {
        this.isLoading = true
      }, false)

      video.addEventListener('playing', () => {
        if (typeof video.webkitAudioDecodedByteCount !== 'undefined') {
          if (video.webkitAudioDecodedByteCount > 0) this.hasAudio = true
          else this.hasAudio = false
        } else if (typeof video.mozHasAudio !== 'undefined') {
          if (video.mozHasAudio) this.hasAudio = true
          else this.hasAudio = false
        } else if (typeof video.audioTracks !== 'undefined') {
          if (video.audioTracks && video.audioTracks.length) this.hasAudio = true
          else this.hasAudio = false
        } else {
          this.hasAudio = false
        }
        //this.isLoading = false
      }, false)

      video.addEventListener('loadstart', (e) => {
        if (e.target.paused) {
          //this.isLoading = false
        } else {
          this.isLoading = true
        }
      }, false)

      video.addEventListener('error', (e) => {
        console.log(e,"video error")
        this.isLoading = false
      }, false)

      video.addEventListener('timeupdate', () => {
        this.isLoading = false
      }, false)

      video.addEventListener('webkitendfullscreen', () => {
        setTimeout(() => {
          video.play()
        }, 1500)
      }, false)
    },
    toggleMute() {
      this.isMuted = !this.isMuted
      this.$refs.video.muted = (this.isMuted)
      this.$emit("changeMute",this.isMuted)
    },
    mute(){
      this.isMuted = true
      this.$refs.video.muted = true
    },
    async goToFullSize() {
      await this.$store.dispatch("changeFullScreen",{isFullscreen: 1,isSchedule: this.type === 'schedule',scheduleInfo: this.schid })
      this.$emit("changeFullscreen",!this.isFullScreen)
      const e = this.$refs.videoWrap
      if (!this.isIos) {
        if (e.requestFullscreen) {
          await e.requestFullscreen()
        } else if (e.mozRequestFullScreen) {
          await e.mozRequestFullScreen()
        } else if (e.webkitRequestFullScreen) {
          await e.webkitRequestFullScreen()
        }
      }
      this.isFullScreen = true
    },
    showControls(el) {
      if (this.$windowWidth > 992) {
        const classes = el.target.parentNode.classList
        let classInVideo = false
        for (let item of classes) {
          console.log(item)
          if (item.indexOf('video') !== -1) {
            classInVideo = true
          }
        }
        if (classInVideo) {
          this.isShowControls = true
        } else {
          this.isShowControls = false
        }
      }
    },
    hideControls(el) {
      if (el.type === 'mouseover' || el.type === 'mouseleave') {
        this.isShowControls = false
      }
    },
    clickToVideo(e) {
      const el = e.target.classList[0]
      if (this.$windowWidth < 993) {
        if (this.isShowControls && (el === 'video__item' || el === 'video')) {
          this.isShowControls = false
        } else {

          this.isShowControls = true
        }
      }
    },
    changeFullScreen() {
      if (!document.fullscreenElement) {
        this.isFullScreen = false
        this.$emit("changeFullscreen",this.isFullScreen)
      }
    },
    changePlay(type) {
      return this.stop = type
    },
    changeZoom() {
      var x = window.innerWidth / 2;
      var y = window.innerHeight / 2;
      this.videoSet.current.z = 1
      this.desktopZoomInstance.smoothZoom(x, y, 0);
    },
    exitScreen() {
      this.$store.dispatch("changeFullScreen",{isFullscreen: 0,isSchedule: this.type === 'schedule' })
      if (!this.isIos){
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      this.isFullScreen = false


      this.$refs.videoContainer.style.transform = null
      this.$emit("changeFullscreen",this.isFullScreen)

    },
    stopVideo(){
      if (this.hlsPlayer != null){
        console.log(this.hlsPlayer)
        this.hlsPlayer.detachMedia();
      }
    },

    fullStop(){
      if (this.hlsPlayer != null){
        this.hlsPlayer.destroy()
      }
    }

  },
  mounted() {
    this.isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    this.isIos = this.$refs.video.canPlayType('application/vnd.apple.mpegurl')
    this.isLoading = true

    this.generateVideo()
    this.playVideo()

    const ro = new ResizeObserver(() => {
      if (this.videoSet.current.z === 1){
        this.mut = {...this.computedFilterObject()}
      }
    });

    ro.observe(this.$refs.video)

  },
  watch: {
    getScale() {
      if (this.videoSet.current.z <= 1) {
        this.videoSet.current.z = 1
        this.videoSet = {
          pinchStart: {
            x: 0,
            y: 0,
          },
          originalSize: {
            width: this.$refs.video.clientWidth,
            height: this.$refs.video.clientHeight
          },
          current: {
            x: 0,
            y: 0,
            z: 1,
            zooming: false,
            width: this.$refs.video.clientWidth,
            height: this.$refs.video.clientHeight
          },
          last: {
            x: 0,
            y: 0,
            z: 1
          },
          type: undefined,
          delta: {x: undefined, y: undefined}
        }

      }
    },
    countTap() {
      setTimeout(() => {
        this.countTap = 0
      }, 500)
      if (this.countTap === 2) {
        this.videoSet.current.z = 1
      }
    },
    isFullScreen() {
      if (this.isFullScreen) {
        document.addEventListener('fullscreenchange', this.changeFullScreen)

        this.desktopZoomInstance = panzoom(this.$refs.videoContainer, {
          maxZoom: 7,
          minZoom: 1,
          bounds: true,
          boundsPadding: 1,
          zoomDoubleClickSpeed: 0,
        },)

        this.desktopZoomInstance.on('zoom', () => {
          this.videoSet.current.z = this.desktopZoomInstance.getTransform().scale
          if (this.desktopZoomInstance.getTransform().scale <= 1){
            this.$refs.video.style.transform = null
          }
        });


      } else {
        document.removeEventListener('fullscreenchange', this.changeFullScreen)
        this.stop = false
        this.videoSet.current.z = 1
        this.desktopZoomInstance.dispose()
      }
    },

    video() {
      this.playVideo()

    },
    getShow() {
      this.playVideo()

    },
    getVolume() {
      const video = this.$refs.video
      if (this.volume === 0) {
        video.volume = 0
        this.isMuted = true
      } else {
        video.volume = this.volume
        this.isMuted = false
      }
    },
    stop(val) {
      const video = this.$refs.video
      if (val) {
        video.pause()
      } else {
        video.currentTime = this.hlsPlayer.liveSyncPosition
        video.play()
      }
    }
  },
  beforeDestroy() {
    if (this.isFullScreen){
      this.exitScreen()
    }

    if (this.hlsPlayer != null && !this.isIos) {
      this.hlsPlayer.detachMedia(this.$refs.video)
      this.hlsPlayer.destroy()
    } else {
      this.$refs.video.pause()
      this.$refs.video.removeAttribute('src') // empty source
      this.$refs.video.load()
    }
    window.removeEventListener('orientationchange', this.loadShow)
  }
}
</script>

<style scoped lang="scss">
.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  bottom: 0;
  @include for-phone-only{
    width: 375px !important;
    height: 239px !important;
  }

  @include for-phone-landscape-up{
    width: 575px !important;
    height: 440px !important;
  }

  @include for-tablet-up{
    width: 768px !important;
    height: 587px !important;
  }

  @include for-desktop-up{
    width: 768px !important;
    height: 587px !important;

  }

  @include for-big-desktop-up{
    width: 768px !important;
    height: 587px !important;

  }
  &_bg {
    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.224365) 50%, rgba(0, 0, 0, 0.0001) 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 25%;
      z-index: 4;
      transition: all .25s;
      opacity: 1;
    }
    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
      bottom: 0;
      height: 25%;
      z-index: 4;
      transition: all .25s;
      opacity: 1;
      left: 0;
      right: 0;
    }
  }

  &__container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: pan-x pan-y;
    background-color: black;

  }

  video {
    width: 100%;
    z-index: 2;
  }


  &__loader {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  &__title {
    font-size: 14px;
    font-family: Roboto, "sans-serif";
    font-weight: 100;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    left: 18px;
    top: 19px;
    color: white;
    z-index: 5;
  }

  &__item {
    //transition: all .25s;
    &_grab {
      cursor: grab;
    }

    &_translate {
      transition: all .25s;
    }

    &_fullscreen{
      @media screen and (orientation: landscape){
        height: 100%;
        width: auto !important;
      }

      @media screen and (orientation: portrait) {
        width: 100%;
        height: auto !important;
      }
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 4;
    color: #fff;
    transform: translateY(-50%) translateX(-50%);
    max-width: 300px;
    width: 100%;

    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.224365) 14.52%, rgba(0, 0, 0, 0.0001) 26.35%, rgba(0, 0, 0, 0.0001) 75.63%, rgba(0, 0, 0, 0.5) 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
      transition: all .25s;
      opacity: 0;
    }

    span {
      max-width: 320px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__info {
    opacity: 1;
    z-index: 5;
    transition: all .5s;

    &:hover {
      opacity: 1;
    }
  }

  &__archive {
    width: 24px;
    height: 15px;
    position: absolute;
    top: 16px;
    right: 22px;
    cursor: pointer;

    &.right {
      right: 60px;
    }

    z-index: 5;
  }

  &__fullscreen {
    width: 20px;
    z-index: 5;
    height: 18px;
    position: absolute;
    bottom: 20px;
    right: 30px;
    cursor: pointer;
  }

  &__share {
    position: absolute;
    right: 22px;
    top: 16px;
    width: 19px;
    height: 17px;
    z-index: 5;

    &:hover {
      filter: brightness(80%);
    }
  }
}

.volume-off--image {
  position: absolute;
  width: 20px;
  height: 20px;
  font-size: 20px;
  bottom: 18px;
  left: 20px;
  z-index: 5;
  cursor: pointer;
}

.volume-on--image {
  position: absolute;
  z-index: 5;
  width: 20px;
  height: 20px;
  font-size: 20px;
  bottom: 18px;
  left: 20px;
  cursor: pointer;
}

.no-audio {
  position: absolute;
  bottom: 24px;
  left: 18px;
  z-index: 5;
  font-size: 11px;
  font-weight: 100;
  letter-spacing: 0.34px;
  font-family: Roboto, sans-serif;
  color: #fff;
}
</style>
