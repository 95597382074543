<template>
    <div class="unpayed--view first" :class="[type]">
        <div class="image--container" v-if="type === 'weekend' || type === 'holiday'">
            <img src="../../assets/img/weekend.gif" draggable="false" v-if="type === 'weekend'">
            <img src="../../assets/img/holiday.gif" draggable="false" v-if="type === 'holiday'">
        </div>
        <div class="text--container">
            <p class="title__text">{{title}}</p>
            <p class="text">{{text}}</p>
        </div>
        <div class="class-name--container" v-if="className != null && className.length > 0">
            <p class="class__name">{{className}}  {{(typeSchool == 1) ? "группа" : "класс"}}</p>
        </div>
        <div class="image--container" v-if="type === 'unpaid'" @click="showInfoModal">
            <img src="../../assets/img/warning1.svg" draggable="false">
        </div>
    </div>
</template>

<script>
    export default {
        name: "UnpayedCard",
        props:{
            title : String,
            text : String,
            className : String,
            type : String,
          letter: String,
          typeSchool: Number
        },
        methods:{
            showInfoModal(){
                this.$modal.show("InfoPayModal")
            }
        }
    }
</script>

<style scoped lang="scss">
    .unpayed--view{
        background: #FFFFFF;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
        border-radius: 4.37px;
        display: flex;
        height: 70px;

        @include for-phone-only{
            width: 375px;

        }

        @include for-phone-landscape-up{
            width: 575px;
        }

        @include for-tablet-up{
            width: 768px;
        }

        @include for-desktop-up{
            width: 768px;

        }

        @include for-big-desktop-up{
            width: 768px;
        }


        .image--container{
            margin-left: 22px;
            margin-top: 15px;
        }

        .text--container{
            margin-top: 15px;
            margin-left: 20px;
            width: 398px;
            .title__text{
                font-family: Roboto, "sans-serif";
                font-size: 16px;
                letter-spacing: 0;
                color: #CF6868;
                font-weight: 400;
            }

            .text{
                font-family: Roboto, "sans-serif";
                font-size: 14px;
                letter-spacing: 0;
                color: black;
                margin-top: 3px;

                @include for-mobile-devices{
                    font-size: 12px;
                }
            }
        }

        .class-name--container{
            margin-left: 152px;
            margin-top: 22px;


            @include for-phone-only{
                margin-left: 0px;

            }

            @include for-phone-landscape-up{
                margin-left: 176px;
            }

            @include for-tablet-up{
                margin-left: 176px;
            }

            @include for-desktop-up{
                margin-left: 176px;

            }

            @include for-big-desktop-up{
                margin-left: 176px;
            }

            .class__name{
                margin-right: 16px;
                font-family: Roboto, "sans-serif";
                letter-spacing: 0;
                font-size: 14px;
                color: #222222;

                background: #F4F4F4;
                width: 98px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 13px;

                pointer-events: none;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
            }
        }
    }


    .unpayed--view.unpaid{

        @include for-phone-only{
            .image--container{
                margin-left: 12px;
            }

            .text--container{
                margin-left: 10px;
            }
        }
    }

    .image--container{
        img{
            width: 40px;
            height: 40px;
        }
    }


    .unpayed--view.unpaid{
        display: flex;
        justify-content: space-between;
        height: 113px !important;
        flex-direction: row;

        .text--container{
            margin-left: 17px;
            margin-top: 21px;


            .title__text{
                line-height: 19px;
                font-weight: 500;
            }

            .text{
                line-height: 18px;
                font-weight: 400;
                font-size: 15px;

                width: 330px;
                margin-top: 10px;
            }
        }

        .image--container{
            margin-right: 16px;
            margin-top: 16px;
            margin-left: -20px;
            img{
                width: 32px;
                height: 32px;
            }
        }
        .image--container:active{
            opacity: 0.75;
        }
    }
</style>
